<template>
  <aRow :gutter="20">
    <aCol
      v-if="
        form.getFieldValue(`type`) == 'Pagamento' ||
        form.getFieldValue(`type`) == 'Recebimento' ||
        form.getFieldValue(`type`) == 'Férias'
      "
      :span="6"
    >
      <aFormItem class="travel-input-outer">
        <label for :class="'filled'"> Módulo do sistema </label>

        <aSelect
          class="travel-input"
          placeholder="Selecione"
          optionFilterProp="txt"
          show-search
          :disabled="disableEventFields"
          :loading="loadingModuleItensArr"
          v-decorator="[
            `module`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
          style="width: 100%"
          @change="onChangeModules"
        >
          <aSelectOption
            v-for="(item, index) of modules"
            :key="index"
            :value="item.meta.slug_module"
            :txt="item.name"
            :data="item"
          >
            {{ item.name }}
          </aSelectOption>
        </aSelect>
      </aFormItem>
    </aCol>

    <aCol
      v-if="
        (form.getFieldValue(`type`) == 'Pagamento' ||
          form.getFieldValue(`type`) == 'Recebimento' ||
          form.getFieldValue(`type`) == 'Férias') &&
        moduleItensArr.length &&
        !['user', 'product-supplier'].includes(form.getFieldValue(`module`))
      "
      :span="6"
    >
      <aFormItem class="travel-input-outer">
        <label for class="filled"> Selecione o Item </label>

        <aSelect
          class="travel-input"
          placeholder="Selecione"
          optionFilterProp="txt"
          allowClear
          :dropdownMatchSelectWidth="false"
          v-decorator="[
            `module_id`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
          show-search
          style="width: 100%"
        >
          <aSelectOption
            v-for="(item, index) of moduleItensArr"
            :key="index"
            :value="item.id"
          >
            ID {{ item.id }} - {{ item?.first_name }} {{ item?.name }}
            {{ item?.trading_name }} {{ item?.code }} {{ item?.value }}
          </aSelectOption>
        </aSelect>
      </aFormItem>
    </aCol>

    <aCol
      v-if="
        (form.getFieldValue(`type`) == 'Pagamento' ||
          form.getFieldValue(`type`) == 'Recebimento' ||
          form.getFieldValue(`type`) == 'Férias') &&
        form.getFieldValue(`module`) == 'user'
      "
      :span="6"
    >
      <aFormItem class="travel-input-outer">
        <label for class="filled"> Usuário </label>

        <aSelect
          class="travel-input"
          placeholder="Selecione um usuário"
          optionFilterProp="txt"
          :disabled="user.list.length == 0"
          :dropdownMatchSelectWidth="false"
          v-decorator="[
            `module_id`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
          show-search
          style="width: 100%"
        >
          <aSelectOption
            v-for="(item, index) of user.list"
            :key="index"
            :value="item.id"
            :txt="item.first_name"
          >
            {{ item.id }} - {{ item.first_name }}
            {{ item.last_name }}
          </aSelectOption>
        </aSelect>
      </aFormItem>
    </aCol>

    <aCol
      v-if="
        (form.getFieldValue(`type`) === 'Pagamento' ||
          form.getFieldValue(`type`) === 'Recebimento') &&
        form.getFieldValue(`module`) === 'product-supplier'
      "
      :span="6"
    >
      <aFormItem class="travel-input-outer">
        <label for class="filled"> Fornecedor </label>

        <aSelect
          class="travel-input"
          placeholder="Selecione um fornecedor"
          optionFilterProp="txt"
          :dropdownMatchSelectWidth="false"
          :disabled="productSupplier.length == 0 || disableEventFields"
          v-decorator="[
            `module_id`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
          show-search
          style="width: 100%"
        >
          <aSelectOption
            v-for="(item, index) of productSupplier.list"
            :key="index"
            :value="item.id"
            :txt="item.trading_name"
          >
            {{ item.id }} - {{ item.trading_name }}
          </aSelectOption>
        </aSelect>
      </aFormItem>
    </aCol>

    <aCol
      v-if="
        (form.getFieldValue(`type`) === 'Pagamento' ||
          form.getFieldValue(`type`) === 'Recebimento') &&
        form.getFieldValue(`module`) === 'product-suppliers'
      "
      :span="2"
    >
      <aTooltip placement="top" title="Cadastrar Fornecedor">
        <a-button
          type="primary"
          shape="circle"
          icon="plus"
          size="small"
          :disabled="disableEventFields"
          @click="openCreateProductSupplier = true"
        />
      </aTooltip>

      <aDrawer
        placement="right"
        width="640px"
        :closable="true"
        :visible="openCreateProductSupplier"
        @close="openCreateProductSupplier = false"
      >
        <template slot="title">
          <i class="fas fa-people-arrows cprimary mr-5"></i>
          NOVO FORNECEDOR
        </template>

        <aForm :form="formSupplier" @submit="submitSupplier">
          <EditProductSupplierDefaultData
            v-if="openCreateProductSupplier"
            :form="formSupplier"
            :edit="false"
            :showField="false"
          />
          <aRow>
            <aCol class="a-center mt-10" :span="24">
              <a-button
                size="large"
                type="primary"
                :loading="loadingCreateProductSupplier"
                html-type="submit"
              >
                CADASTRAR FORNECEDOR
              </a-button>
            </aCol>
          </aRow>
        </aForm>
      </aDrawer>
    </aCol>
  </aRow>
</template>

<script>
import userMixins from "@/mixins/user/userMixins.js";
import EditProductSupplierDefaultData from "@/components/product-suppliers/forms/EditProductSupplierDefaultData.vue";

export default {
  name: "EditEventModulesSection",
  props: {
    form: Object,
    event: Object,
    edit: Boolean,
    disableEventFields: Boolean,
    productSupplier: Object,
  },
  mixins: [userMixins],
  components: { EditProductSupplierDefaultData },
  data() {
    return {
      formSupplier: this.$form.createForm(this),
      loadingModuleItensArr: false,
      openCreateProductSupplier: false,
      modules: [],
      moduleItensArr: [],
    };
  },
  mounted() {
    this.user.pagination.perPage = 50;
    this.getUsers();

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=100&category_id=38&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.modules = data.data;
        this.getModuleItens(this.event.module);
      });
  },
  methods: {
    async getModuleItens(module) {
      this.loadingModuleItensArr = true;

      this.$http
        .get(`/${module}/list?page=1&per_page=500&order=desc&order-by=id`)
        .then(({ data }) => {
          this.moduleItensArr = data.data;
          setTimeout(() => {
            this.form.setFieldsValue({
              module_id: this.event.raw.module_id,
            });
          }, 100);
        })
        .finally(() => (this.loadingModuleItensArr = false));
    },
    onChangeModules(value, options) {
      const moduleItensSelector =
        options.data.attrs.data.meta.module_itens_selector;

      this.moduleItensArr = [];

      if (![undefined, ""].includes(moduleItensSelector))
        this.getModuleItens(moduleItensSelector);

      if (this.edit) {
        setTimeout(() => {
          this.form.setFieldsValue({
            module_id: this.event.raw.module_id,
          });
        }, 300);
      }
    },
    submitSupplier(e) {
      e.preventDefault();
      this.formSupplier.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.category = JSON.stringify(values.category);
        values.product_supplier_ids = JSON.stringify(
          values.product_supplier_ids
        );
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        if (!err) {
          this.loadingCreateProductSupplier = true;

          this.$http
            .post("/product-supplier/create", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingCreateProductSupplier = false;
              this.getProductSuppliers();
              this.openCreateProductSupplier = false;

              setTimeout(() => {
                if (data.id) {
                  this.form.setFieldsValue({
                    module_id: data.id,
                  });
                }
              }, 500);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingCreateProductSupplier = false;
            });
        }
      });
    },
  },
};
</script>
