var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aForm',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":18}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Nome ")]),_vm._v(" "),_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `name`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `name`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva um nome para o evento/atividade","disabled":_vm.disableEventFields}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Tipo de atividade ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `type`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `type`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","disabled":_vm.disableEventFields,"show-search":""}},_vm._l((_vm.eventsType),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.name,"txt":item.name}},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Status ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `status`,
            {
              initialValue: 'Em aberto',
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `status`,\n            {\n              initialValue: 'Em aberto',\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","disabled":_vm.disableEventFields}},_vm._l((_vm.status),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.value,"txt":item.value}},[_vm._v("\n            "+_vm._s(item.value)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Data "+_vm._s(_vm.form.getFieldValue(`type`)))]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
            `date`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `date`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":`Selecione`,"format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled":_vm.disableEventFields}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),(_vm.form.getFieldValue(`type`) == 'Férias')?_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Data Final "+_vm._s(_vm.form.getFieldValue(`type`)))]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
            `end_date`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `end_date`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":`Selecione`,"disabled":_vm.disableEventFields,"format":"DD/MM/YYYY","showToday":false,"allowClear":true}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$root.isAdmin() && _vm.form.getFieldValue(`type`) != 'Evento Pessoal')?_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Empresa ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `company_id`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `company_id`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","disabled":_vm.disableEventFields,"show-search":""},on:{"change":_vm.getCompanyBranchesOnChange}},_vm._l((_vm.companiesList),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n            "+_vm._s(item.id)+" - "+_vm._s(item.trading_name)+"\n          ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$root.isAdmin() && _vm.form.getFieldValue(`type`) != 'Evento Pessoal')?_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Filial ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `company_branch_id`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `company_branch_id`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","disabled":_vm.companyBranchesList.length == 0 || _vm.disableEventFields,"show-search":""}},_vm._l((_vm.companyBranchesList),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n            "+_vm._s(item.id)+" - "+_vm._s(item.name)+"\n          ")])}),1)],1)],1):_vm._e(),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Vencimento (opcional)")]),_vm._v(" "),_c('aDatePicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
            `due_date`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `due_date`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":`Selecione`,"format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled":_vm.disableEventFields}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Data da competência (opcional)")]),_vm._v(" "),_c('aDatePicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
            `competence_date`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `competence_date`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":`Selecione`,"format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled":_vm.disableEventFields}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[(_vm.form.getFieldValue(`type`))?_c('EditEventModulesSection',{attrs:{"form":_vm.form,"edit":_vm.edit,"event":_vm.event,"productSupplier":_vm.productSupplier,"disableEventFields":_vm.disableEventFields}}):_vm._e()],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',staticStyle:{"top":"-50px"},attrs:{"for":""}},[_vm._v("\n          Descrição (opcional)\n        ")]),_vm._v(" "),_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `description`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `description`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-textarea",attrs:{"type":"textarea","placeholder":"Escreva aqui...","disabled":_vm.disableEventFields}})],1)],1),_vm._v(" "),(
        _vm.form.getFieldValue(`type`) == 'Pagamento' ||
        _vm.form.getFieldValue(`type`) == 'Recebimento' ||
        _vm.form.getFieldValue(`type`) == 'Férias'
      )?[_c('aCol',{attrs:{"span":24}},[_c('h2',[_vm._v("Dados do Pagamento")])]),_vm._v(" "),_c('aCol',{attrs:{"span":8}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("Valor (R$)")]),_vm._v(" "),_c('aInput',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
              `value`,
              {
                rules: [
                  {
                    required:
                      _vm.form.getFieldValue(`type`) == 'Férias' ? false : true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `value`,\n              {\n                rules: [\n                  {\n                    required:\n                      form.getFieldValue(`type`) == 'Férias' ? false : true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":`Valor do ${
              _vm.form.getFieldValue(`type`) ? _vm.form.getFieldValue(`type`) : '...'
            } (R$)`,"disabled":_vm.disableEventFields}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":8}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Formas de Pagamento ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `payment_methods`,
              {
                rules: [
                  {
                    required:
                      _vm.form.getFieldValue(`type`) == 'Férias' ? false : true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `payment_methods`,\n              {\n                rules: [\n                  {\n                    required:\n                      form.getFieldValue(`type`) == 'Férias' ? false : true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Formas de Pagamento","optionFilterProp":"txt","disabled":false,"show-search":""},on:{"change":_vm.onSelectPaymentMethods}},_vm._l((_vm.paymentMethods),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.name,"txt":item.name}},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")])}),1)],1)],1),_vm._v(" "),(
          ['Pagamento', 'Recebimento', 'Férias'].includes(
            _vm.form.getFieldValue('type')
          )
        )?_c('aCol',{attrs:{"span":8}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Banco ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `bank_id`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `bank_id`,\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Busque um banco","optionFialterProp":"txt","disabled":false,"show-search":""}},_vm._l((_vm.companyBanksList),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.company_name)+" ("+_vm._s(item.account)+")\n            ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(
          (_vm.form.getFieldValue(`type`) == 'Pagamento' &&
            _vm.form.getFieldValue(`payment_methods`) == 'Cartão de Crédito') ||
          (_vm.form.getFieldValue(`type`) == 'Recebimento' &&
            _vm.form.getFieldValue(`payment_methods`) == 'Cartão de Crédito') ||
          (_vm.form.getFieldValue(`type`) == 'Férias' &&
            _vm.form.getFieldValue(`payment_methods`) == 'Cartão de Crédito')
        )?_c('aCol',{attrs:{"span":8}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Cartão de Crédito ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `company_credit_card_id`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `company_credit_card_id`,\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Busque um cartão","optionFialterProp":"txt","disabled":_vm.disableEventFields,"show-search":""}},_vm._l((_vm.companyCreditCardList),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n              "+_vm._s(item.id)+" -\n              "+_vm._s(item.customer.first_name)+"\n              "+_vm._s(item.customer.last_name)+" ("+_vm._s(item.card_number)+")\n            ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(
          ['Pagamento', 'Recebimento'].includes(_vm.form.getFieldValue(`type`))
        )?_c('aCol',{attrs:{"span":8}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Fornecedor ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `product_supplier_id`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `product_supplier_id`,\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","disabled":false,"show-search":""}},_vm._l((_vm.productSupplier.list),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.trading_name)+"\n            ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(
          ['Pagamento', 'Recebimento'].includes(_vm.form.getFieldValue(`type`))
        )?_c('aCol',{attrs:{"span":8}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Voyage ID ")]),_vm._v(" "),_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `voyage_id`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `voyage_id`,\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":" Voyage ID (opcional)","disabled":_vm.disableEventFields}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e(),_vm._v(" "),_c('aCol',{attrs:{"span":8}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Prioridade ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `priority`,
              {
                initialValue: 'Média',
                rules: [
                  {
                    required:
                      _vm.form.getFieldValue(`type`) == 'Férias' ? false : true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `priority`,\n              {\n                initialValue: 'Média',\n                rules: [\n                  {\n                    required:\n                      form.getFieldValue(`type`) == 'Férias' ? false : true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Prioridade","optionFilterProp":"txt","disabled":_vm.disableEventFields}},_vm._l((_vm.priorities),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.value,"txt":item.value}},[_vm._v("\n              "+_vm._s(item.value)+"\n            ")])}),1)],1)],1)]:_vm._e(),_vm._v(" "),(
        _vm.form.getFieldValue(`type`) == 'Pagamento' ||
        _vm.form.getFieldValue(`type`) == 'Recebimento' ||
        _vm.form.getFieldValue(`type`) == 'Férias'
      )?_c('aCol',{staticClass:"mb-20",attrs:{"span":24}},[_c('aCollapse',{staticClass:"extra-event",attrs:{"expandIconPosition":"right"},model:{value:(_vm.activeCollapse),callback:function ($$v) {_vm.activeCollapse=$$v},expression:"activeCollapse"}},[(_vm.edit && _vm.attachment)?_c('aCollapse-panel',{key:"2",attrs:{"header":_vm.form.getFieldValue(`type`) == 'Pagamento' ||
            _vm.form.getFieldValue(`type`) == 'Recebimento' ||
            _vm.form.getFieldValue(`type`) == 'Férias'
              ? `ANEXO: A Pagar`
              : `ANEXO 1`,"disabled":false,"forceRender":""}},[_c('aCol',{staticClass:"mb-20",attrs:{"span":24}},[(_vm.attachment)?_c('div',{staticClass:"file-link relative"},[(_vm.attachment)?_c('a',{attrs:{"href":_vm.attachment,"target":"_blank"}},[(_vm.checkFileType(_vm.attachment))?_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":_vm.attachment,"alt":"img"}}):_c('aIcon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"paper-clip"}})],1):_vm._e()]):_vm._e()])],1):_vm._e(),_vm._v(" "),(
            _vm.edit &&
            _vm.extraAttachment &&
            (_vm.form.getFieldValue(`type`) == 'Pagamento' ||
              _vm.form.getFieldValue(`type`) == 'Recebimento')
          )?_c('aCollapse-panel',{key:"3",attrs:{"header":_vm.form.getFieldValue(`type`) == 'Pagamento' ||
            _vm.form.getFieldValue(`type`) == 'Recebimento'
              ? `ANEXO: Comprovante Pagamento`
              : `ANEXO 2`,"disabled":false,"forceRender":""}},[_c('aCol',{staticClass:"mb-20",attrs:{"span":24}},[(_vm.extraAttachment)?_c('div',{staticClass:"file-link relative"},[(_vm.extraAttachment)?_c('a',{attrs:{"href":_vm.extraAttachment,"target":"_blank"}},[(_vm.checkFileType(_vm.extraAttachment))?_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":_vm.extraAttachment,"alt":"img"}}):_c('aIcon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"paper-clip"}})],1):_vm._e()]):_vm._e()])],1):_vm._e(),_vm._v(" "),(
            _vm.edit &&
            _vm.extraAttachment2 &&
            (_vm.form.getFieldValue(`type`) == 'Pagamento' ||
              _vm.form.getFieldValue(`type`) == 'Recebimento')
          )?_c('aCollapse-panel',{key:"4",attrs:{"header":_vm.form.getFieldValue(`type`) == 'Pagamento' ||
            _vm.form.getFieldValue(`type`) == 'Recebimento'
              ? `ANEXO: NFe`
              : `ANEXO 3`,"disabled":false,"forceRender":""}},[_c('aCol',{staticClass:"mb-20",attrs:{"span":24}},[(_vm.extraAttachment2)?_c('div',{staticClass:"file-link relative"},[(_vm.extraAttachment2)?_c('a',{attrs:{"href":_vm.extraAttachment2,"target":"_blank"}},[(_vm.checkFileType(_vm.extraAttachment2))?_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":_vm.extraAttachment2,"alt":"img"}}):_c('aIcon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"paper-clip"}})],1):_vm._e()]):_vm._e()])],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('aCol',{staticClass:"a-center mt-10 mb-20",attrs:{"span":24}},[_c('aButton',{attrs:{"type":"primary","loading":_vm.loadingCreateEvent,"html-type":"submit","disabled":false}},[_vm._v("\n        "+_vm._s(!_vm.edit ? "Criar atividade" : "Atualizar atividade")+"\n      ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }