<template>
  <div class="pd-20">
    <a-page-header class="pd-0" sub-title="">
      <div slot="title">
        <h1><a-icon type="calendar" class="gradient" /> Lista de atividades</h1>
      </div>
      <div slot="extra">
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="excelFile.data"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img
            class="c-pointer ml-10 mr-10"
            src="@/assets/images/dashboard/excel.png"
            alt="img"
          />
        </downloadExcel>

        <a-tooltip placement="left">
          <template slot="title"> Ver calendário </template>
          <a-button
            class="mr-10"
            type="primary"
            ghost
            @click="$router.push(`calendar`)"
          >
            <a-icon type="calendar" />
          </a-button>
        </a-tooltip>

        <a-button type="primary" ghost @click="openCreateEventModal = true">
          NOVO ATIVIDADE
        </a-button>
      </div>
    </a-page-header>

    <aRow v-if="$root.isAdmin()" class="haya-panels mb-20" :gutter="[10, 10]">
      <aCol :span="8">
        <div class="box">
          <div class="title"></div>
          <div class="total cblack">R$ {{ event.meta.total_federal }}</div>
          <div class="txt">FEDERAL</div>
          <div class="footer">
            <aRow type="flex" justify="space-between">
              <aCol style="color: rgb(255, 189, 189)">
                Pendente: R$
                {{ event.meta.total_federal_pending }}
              </aCol>
              <aCol style="color: rgb(228, 255, 217)">
                Pago: R$ {{ event.meta.total_federal_paid }}
              </aCol>
            </aRow>
          </div>
        </div>
      </aCol>

      <aCol :span="8">
        <div class="box">
          <div class="title"></div>
          <div class="total cblack">R$ {{ event.meta.total_prudente }}</div>
          <div class="txt">PRUDENTE</div>
          <div class="footer">
            <aRow type="flex" justify="space-between">
              <aCol style="color: rgb(255, 189, 189)">
                Pendente: R$
                {{ event.meta.total_prudente_pending }}
              </aCol>
              <aCol style="color: rgb(228, 255, 217)">
                Pago: R$ {{ event.meta.total_prudente_paid }}
              </aCol>
            </aRow>
          </div>
        </div>
      </aCol>

      <aCol :span="8">
        <div class="box">
          <div class="title"></div>
          <div class="total cblack">R$ {{ event.meta.total_bandeirantes }}</div>
          <div class="txt">BANDEIRANTES</div>
          <div class="footer">
            <aRow type="flex" justify="space-between">
              <aCol style="color: rgb(255, 189, 189)">
                Pendente: R$
                {{ event.meta.total_bandeirantes_pending }}
              </aCol>
              <aCol style="color: rgb(228, 255, 217)">
                Pago: R$
                {{ event.meta.total_bandeirantes_paid }}
              </aCol>
            </aRow>
          </div>
        </div>
      </aCol>
    </aRow>

    <aCollapse
      class="travel-filters expandable mb-20"
      activeKey="0"
      expandIconPosition="right"
    >
      <aCollapse-panel key="1">
        <template #header> <aIcon type="filter" /> FILTRAR </template>

        <aRow class="mt-0 mb-0" :gutter="20">
          <aCol :span="5">
            <div class="travel-input">
              <label for class="filled">Nome</label>
              <a-input
                placeholder="Escreva aqui"
                v-model="event.filters.searchTerm"
                @change="getEvents()"
              />
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label for class="filled">Período</label>

              <a-range-picker
                v-model="event.filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getEvents()"
              />
            </div>
          </aCol>

          <aCol :span="10">
            <div class="travel-input">
              <label for class="filled">Status</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o status do contrato"
                mode="multiple"
                v-model="event.filters.status.selected"
                show-search
                style="width: 100%"
                @change="getEvents()"
              >
                <a-select-option
                  v-for="(item, index) of event.filters.status.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol v-if="$root.isAdmin()" :span="5">
            <div class="travel-input">
              <label for class="filled">Prioridade</label>
              <a-select
                class="travel-input"
                placeholder="Selecione "
                mode="multiple"
                v-model="event.filters.priority.selected"
                show-search
                style="width: 100%"
                @change="getEvents()"
              >
                <a-select-option
                  v-for="(item, index) of event.filters.priority.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol v-if="$root.isAdmin()" :span="9">
            <div class="travel-input">
              <label for class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                optionFilterProp="txt"
                mode="multiple"
                v-model="event.filters.users.selected"
                show-search
                style="width: 100%"
                @change="getEvents()"
              >
                <a-select-option
                  v-for="(item, index) of event.filters.users.list"
                  :key="index"
                  :value="item.id"
                  :txt="item.label"
                >
                  {{ item.id }} - {{ item.first_name }}
                  {{ item.last_name }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol v-if="$root.isAdmin()" :span="7">
            <div class="travel-input">
              <label for class="filled">Valor (R$)</label>
              <a-input-group compact>
                <a-input
                  type="number"
                  style="width: 45%; text-align: center; border-radius: 0"
                  placeholder="Min."
                  v-model="event.filters.price.min"
                  @change="getEvents()"
                />
                <a-input
                  style="
                    width: 10%;
                    border-left: 0;
                    pointer-events: none;
                    backgroundcolor: #fff;
                    border: 0;
                  "
                  placeholder="até"
                />
                <a-input
                  type="number"
                  style="
                    width: 45%;
                    text-align: center;
                    border-left: 0;
                    border-radius: 0;
                  "
                  placeholder="Max"
                  v-model="event.filters.price.max"
                  @change="getEvents()"
                />
              </a-input-group>
            </div>
          </aCol>

          <aCol v-if="$root.isAdmin()" :span="3">
            <div class="travel-input">
              <label for class="filled">ID Voyage</label>
              <a-input
                placeholder="Escreva aqui"
                v-model="event.filters.voyageId"
                @change="getEvents()"
              />
            </div>
          </aCol>

          <aCol :span="3">
            <div class="travel-input">
              <label for class="filled">Empresas</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                v-model="event.filters.companies"
                show-search
                allow-clear
                style="width: 100%"
                @change="getEvents()"
              >
                <a-select-option
                  v-for="(item, index) of companies.list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.trading_name }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol :span="8">
            <div class="travel-input">
              <label for class="filled">Tipo</label>
              <a-select
                class="travel-input"
                placeholder="Selecione "
                mode="multiple"
                v-model="event.filters.type.selected"
                show-search
                style="width: 100%"
                @change="getEvents()"
              >
                <a-select-option
                  v-for="(item, index) of event.filters.type.list"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>
        </aRow>
      </aCollapse-panel>
    </aCollapse>

    <aTable
      class="travel-table"
      :columns="[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 80,
          sorter: true,
        },
        {
          title: 'Tipo',
          dataIndex: 'type',
          key: 'type',
          width: 130,
        },
        {
          title: 'Nome ',
          dataIndex: 'name',
          key: 'name',
          width: this.$root.isAdmin() ? 110 : 300,
          sorter: true,
          scopedSlots: { customRender: 'name' },
        },
        this.$root.isAdmin()
          ? {
              title: 'Valor ',
              dataIndex: 'value',
              key: 'value',
              sorter: true,
              width: 110,
            }
          : {},
        this.$root.isAdmin()
          ? {
              title: 'Empresa',
              width: 140,
              scopedSlots: {
                customRender: 'company',
              },
            }
          : {},
        this.$root.isAdmin()
          ? {
              title: 'Relacionado',
              scopedSlots: {
                customRender: 'module_entry',
              },
              width: 110,
            }
          : {},
        this.$root.isAdmin()
          ? {
              title: 'Pagamento',
              dataIndex: 'payment_methods',
              key: 'payment_methods',
              sorter: true,
              width: 110,
              scopedSlots: { customRender: 'payment_methods' },
            }
          : {},
        this.$root.isAdmin()
          ? {
              title: 'Prioridade',
              dataIndex: 'priority',
              width: 110,
              key: 'priority',
              sorter: true,
            }
          : {},
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          sorter: true,
          width: 110,
          scopedSlots: { customRender: 'status' },
        },
        {
          title: this.$root.isAdmin() ? 'Vencimento' : 'Data',
          dataIndex: 'date',
          key: 'date',
          width: 120,
          sorter: true,
        },
        this.$root.isAdmin()
          ? {
              title: 'Pagamento',
              key: 'payday',
              width: 110,
              sorter: true,
              scopedSlots: {
                customRender: 'payday',
              },
            }
          : {},
        this.$root.isAdmin()
          ? {
              title: 'ID Voyage',
              dataIndex: 'voyage_id',
              key: 'voyage_id',
              sorter: true,
              width: 110,
            }
          : {},
        {
          title: '',
          key: 'user',
          scopedSlots: {
            customRender: 'user',
          },
        },

        {
          title: ``,
          key: 'action',
          align: 'right',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ]"
      :data-source="event.list"
      :loading="event.loading"
      :pagination="false"
      @change="eventTableChange"
      :scroll="{ x: 1300 }"
    >
      <span slot="attachmentTitle"><a-icon type="paper-clip" /> </span>
      <a slot="id" slot-scope="text"> {{ text }}</a>

      <div slot="name" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          <div
            class="dotted-phrase"
            :style="$root.isAdmin() ? 'width: 100px; padding-right: 10px' : ''"
          >
            {{ text }}
          </div>
        </a-tooltip>
      </div>

      <div slot="payment_methods" slot-scope="text">
        <div class="dotted-phrase" style="width: 100px; padding-right: 10px">
          {{ text ? text : "---" }}
        </div>
      </div>

      <div slot="color" slot-scope="record">
        <a-avatar
          shape="circle"
          size="small"
          :style="{
            backgroundColor: record.color,
          }"
        >
        </a-avatar>
      </div>

      <div slot="company" slot-scope="record">
        {{ record.company.trading_name }}
      </div>

      <div slot="module_entry" slot-scope="record">
        <div class="dotted-phrase" style="width: 100px; padding-right: 10px">
          {{
            record.module_entry.id_name ? record.module_entry.id_name : "---"
          }}
        </div>
      </div>

      <div slot="payday" slot-scope="record">
        {{ record.payday == "0000-00-00" ? "---" : formatDate(record.payday) }}
      </div>

      <div slot="attachment" slot-scope="record">
        <div v-if="record.attachment">
          <a-button
            @click="openLink(record.attachment)"
            type="link"
            style="font-size: 18px"
          >
            <a-icon type="paper-clip" />
          </a-button>
        </div>
      </div>

      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }}
            {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              :size="30"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>
      <div slot="bank" slot-scope="record">
        {{ record.name }}
      </div>
      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Editar">
            <a class="edit ml-15" @click="editEvent(record.id)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </aTable>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :page-size-options="pageSizeOptions"
        :default-current="event.pagination.page"
        :total="event.pagination.total"
        :page-size.sync="event.pagination.perPage"
        @change="changeEventsPage"
        @showSizeChange="changeEventsPageSize"
      />
    </div>

    <aDrawer
      class="create-event"
      :visible="openCreateEventModal"
      :footer="false"
      @close="openCreateEventModal = false"
      width="800px"
    >
      <template slot="title">
        <a-icon type="calendar" class="mr-5 cprimary" /> NOVA ATIVIDADE
      </template>
      <EditEventDefaultData
        v-if="openCreateEventModal"
        :date="formatDateModel(selectedDate, 'yyyy-MM-dd', '/')"
        @listEvents="listEvents"
      />
    </aDrawer>

    <aDrawer
      :visible="openEditEventModal"
      :footer="false"
      @close="closeEditEventModal"
      width="800px"
    >
      <template slot="title">
        <a-icon type="calendar" class="mr-5 cprimary" />
        {{ theEvent.id }} - {{ theEvent.name }} ({{ theEvent.type }})
      </template>

      <a-tabs
        v-if="openEditEventModal"
        type="card"
        default-active-key="1"
        style="margin-top: -10px"
      >
        <a-tab-pane key="1" tab="Dados" force-render>
          <EditEventDefaultData
            class="mt-10"
            :edit="true"
            :event="theEvent"
            @listEvents="listEvents"
          />
        </a-tab-pane>
        <a-tab-pane key="2" force-render>
          <template slot="tab"> Recorrência </template>
          Este {{ theEvent.type }} é recorrente? Selecione os dias que ele deve
          ser criado com os mesmos dados do
          <b>{{ theEvent.type }} ID {{ theEvent.id }} - {{ theEvent.name }}</b>
          abaixo:
          <br />
          <br />

          A data atual é
          <b>{{ theEvent.date ? formatDate(theEvent.date) : "" }}</b
          >.

          <div class="multi-select-calendar mt-20">
            <m-date-picker
              v-model="recurringDates"
              :disp="[
                'D',
                'S',
                'T',
                'Q',
                'Q',
                'S',
                'S',
                '',
                '',
                'CANCELAR',
                'OK',
                'LIMPAR',
              ]"
              :always-display="true"
            ></m-date-picker>
          </div>

          <div class="a-center" v-if="recurringDates.length > 0">
            <a-popconfirm
              placement="top"
              ok-text="Sim"
              cancel-text="Não"
              @confirm="createRecurringDates"
            >
              <template slot="title">
                Tem certeza que deseja criar as datas recorrentes?
              </template>
              <a-button type="primary" :loading="loadingRecurringDates">
                CRIAR DATAS RECORRENTES
              </a-button>
            </a-popconfirm>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" force-render>
          <template slot="tab"> Notas </template>
          <Notes module="event" :moduleId="theEvent.id" />
        </a-tab-pane>
        <aTabPane key="4" force-render>
          <template slot="tab"> Arquivos </template>
          <FilesTable
            moduleName="event"
            :moduleId="theEvent.id"
            :searchModuleIds="theEvent.id"
            searchModules="event"
            s3Folder="events"
          />
        </aTabPane>
        <aTabPane v-if="theEvent.raw.invoice_id != 0" key="5">
          <template slot="tab"> Paxes da Fatura </template>
          <EventInvoiceSalesTab :event="theEvent" />
        </aTabPane>
      </a-tabs>
    </aDrawer>
  </div>
</template>

<script>
import { format } from "date-fns";
import EditEventDefaultData from "@/components/calendar/forms/EditEventDefaultData.vue";
import eventsMixins from "@/mixins/events/eventsMixins.js";
import formatThings from "@/mixins/general/formatThings";
import companyMixins from "@/mixins/companies/companyMixins";
import Notes from "@/components/general/Notes.vue";
import FilesTable from "@/components/general/FilesTable.vue";
import EventInvoiceSalesTab from "@/components/calendar/tabs/EventInvoiceSalesTab.vue";

export default {
  name: "ListEvents",
  components: { EditEventDefaultData, Notes, FilesTable, EventInvoiceSalesTab },
  mixins: [eventsMixins, formatThings, companyMixins],
  data() {
    return {
      openCreateEventModal: false,
      openEventsModal: false,
      openEditEventModal: false,
      selectedDate: "",
      recurringDates: [],
      recurringDatesData: {},
      loadingRecurringDates: false,
      theEvent: {},
      pageSizeOptions: ["10", "25", "50", "100", "200", "500"],
      excelFile: {
        header: "Atividades",
        fileName: "Atividades.xls",
        collumns: {
          ID: "id",
          Tipo: "type",
          Nome: "name",
          Valor: "value",
          Empresa: {
            field: "company",
            callback: (company) => {
              return `${company.trading_name}`;
            },
          },
          "Relacionado à": {
            field: "module_entry",
            callback: (module_entry) => {
              return `${
                module_entry.id_name != undefined ? module_entry.id_name : "---"
              }`;
            },
          },
          Pagamento: "payment_methods",
          Prioridade: "priority",
          Status: "status",
          Data: "date",
          "Data do Pagamento": {
            field: "payday",
            callback: (payday) => {
              return `${payday == "0000-00-00" ? "" : this.formatDate(payday)}`;
            },
          },
          "ID Voyage": "voyage_id",
          Usuário: {
            field: "user",
            callback: (user) => {
              return `${user.first_name} ${user.last_name}`;
            },
          },
        },
        data: [],
        footer: [],
      },
    };
  },
  computed: {
    routeTab() {
      document.title = "Atividades - Haya";
      return {
        title: `Atividades`,
        icon: "ordered-list",
        tips: "",
      };
    },
  },
  beforeMount() {
    this.event.type = "list";

    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.event.filters.period.selected = [`${firstDay}`, `${lastDay}`];

    this.event.filters.orderBy = "date";
    this.event.pagination.perPage = 25;
    this.getEvents();
    if (this.$root.isAdmin()) {
      this.$http
        .get(
          `/category-entry/list?page=1&per_page=10&category_id=13&order-by=name&order=ascend`
        )
        .then(({ data }) => {
          this.event.filters.type.list = data.data;
        });
    } else {
      this.event.filters.type.list = [{ name: "Evento Pessoal" }];
    }

    this.$http.get("/user/list?page=1&per_page=100").then(({ data }) => {
      this.event.filters.users.list = data.data;
    });
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    closeEditEventModal() {
      this.openEditEventModal = false;
      this.recurringDates = [];
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    editEvent(id) {
      this.$http
        .post("/event/details?id=" + id)
        .then(({ data }) => {
          this.openEditEventModal = true;
          this.theEvent = data;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    selectDate(date) {
      this.openEventsModal = true;
      this.event.type = "list";

      if (this.event.mode == "month") {
        this.selectedDate = this.formatMultiDates(date);
        this.event.filters.date = this.formatDateModel(date, "yyyy-MM-dd");
      }

      if (this.event.mode == "year") {
        this.event.type = "list";
        this.selectedDate = this.formatDateModel(date, "MMMM yyyy");
        this.event.filters.date = "";
        this.event.filters.month = this.formatDateModel(date, "MM");
        this.event.filters.year = date.getFullYear();
      }

      setTimeout(() => {
        this.getEvents();
      }, 100);
    },
    calendarPanelChange(date, mode) {
      this.event.type = "calendar";
      this.event.mode = mode;
      this.event.filters.date = "";

      setTimeout(() => {
        this.getEvents();
      }, 100);
    },
    calendarChange(date) {
      console.log(date._d);
    },
    closeDate() {
      this.openEventsModal = false;
      this.event.list = [];
    },
    listEvents() {
      this.getEvents();
      this.openCreateEventModal = false;
    },
  },
};
</script>
