var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aRow',{attrs:{"gutter":20}},[(
      _vm.form.getFieldValue(`type`) == 'Pagamento' ||
      _vm.form.getFieldValue(`type`) == 'Recebimento' ||
      _vm.form.getFieldValue(`type`) == 'Férias'
    )?_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Módulo do sistema ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `module`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `module`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","show-search":"","disabled":_vm.disableEventFields,"loading":_vm.loadingModuleItensArr},on:{"change":_vm.onChangeModules}},_vm._l((_vm.modules),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.meta.slug_module,"txt":item.name,"data":item}},[_vm._v("\n          "+_vm._s(item.name)+"\n        ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(
      (_vm.form.getFieldValue(`type`) == 'Pagamento' ||
        _vm.form.getFieldValue(`type`) == 'Recebimento' ||
        _vm.form.getFieldValue(`type`) == 'Férias') &&
      _vm.moduleItensArr.length &&
      !['user', 'product-supplier'].includes(_vm.form.getFieldValue(`module`))
    )?_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v(" Selecione o Item ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `module_id`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `module_id`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","allowClear":"","dropdownMatchSelectWidth":false,"show-search":""}},_vm._l((_vm.moduleItensArr),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.id}},[_vm._v("\n          ID "+_vm._s(item.id)+" - "+_vm._s(item?.first_name)+" "+_vm._s(item?.name)+"\n          "+_vm._s(item?.trading_name)+" "+_vm._s(item?.code)+" "+_vm._s(item?.value)+"\n        ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(
      (_vm.form.getFieldValue(`type`) == 'Pagamento' ||
        _vm.form.getFieldValue(`type`) == 'Recebimento' ||
        _vm.form.getFieldValue(`type`) == 'Férias') &&
      _vm.form.getFieldValue(`module`) == 'user'
    )?_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v(" Usuário ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `module_id`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `module_id`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione um usuário","optionFilterProp":"txt","disabled":_vm.user.list.length == 0,"dropdownMatchSelectWidth":false,"show-search":""}},_vm._l((_vm.user.list),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.id,"txt":item.first_name}},[_vm._v("\n          "+_vm._s(item.id)+" - "+_vm._s(item.first_name)+"\n          "+_vm._s(item.last_name)+"\n        ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(
      (_vm.form.getFieldValue(`type`) === 'Pagamento' ||
        _vm.form.getFieldValue(`type`) === 'Recebimento') &&
      _vm.form.getFieldValue(`module`) === 'product-supplier'
    )?_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v(" Fornecedor ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `module_id`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `module_id`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione um fornecedor","optionFilterProp":"txt","dropdownMatchSelectWidth":false,"disabled":_vm.productSupplier.length == 0 || _vm.disableEventFields,"show-search":""}},_vm._l((_vm.productSupplier.list),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n          "+_vm._s(item.id)+" - "+_vm._s(item.trading_name)+"\n        ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(
      (_vm.form.getFieldValue(`type`) === 'Pagamento' ||
        _vm.form.getFieldValue(`type`) === 'Recebimento') &&
      _vm.form.getFieldValue(`module`) === 'product-suppliers'
    )?_c('aCol',{attrs:{"span":2}},[_c('aTooltip',{attrs:{"placement":"top","title":"Cadastrar Fornecedor"}},[_c('a-button',{attrs:{"type":"primary","shape":"circle","icon":"plus","size":"small","disabled":_vm.disableEventFields},on:{"click":function($event){_vm.openCreateProductSupplier = true}}})],1),_vm._v(" "),_c('aDrawer',{attrs:{"placement":"right","width":"640px","closable":true,"visible":_vm.openCreateProductSupplier},on:{"close":function($event){_vm.openCreateProductSupplier = false}}},[_c('template',{slot:"title"},[_c('i',{staticClass:"fas fa-people-arrows cprimary mr-5"}),_vm._v("\n        NOVO FORNECEDOR\n      ")]),_vm._v(" "),_c('aForm',{attrs:{"form":_vm.formSupplier},on:{"submit":_vm.submitSupplier}},[(_vm.openCreateProductSupplier)?_c('EditProductSupplierDefaultData',{attrs:{"form":_vm.formSupplier,"edit":false,"showField":false}}):_vm._e(),_vm._v(" "),_c('aRow',[_c('aCol',{staticClass:"a-center mt-10",attrs:{"span":24}},[_c('a-button',{attrs:{"size":"large","type":"primary","loading":_vm.loadingCreateProductSupplier,"html-type":"submit"}},[_vm._v("\n              CADASTRAR FORNECEDOR\n            ")])],1)],1)],1)],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }