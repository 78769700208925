var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aRow',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Razão social ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `company_name`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `company_name`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Razão social"},on:{"change":_vm.verifyProductExistence}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"}),_vm._v(" "),_c('template',{slot:"suffix"},[(_vm.productSupplier.filters.productExists)?_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v("\n              Este nome já está sendo utilizado\n            ")]),_vm._v(" "),_c('a-icon',{staticClass:"red",attrs:{"type":"close"}})],2):_vm._e(),_vm._v(" "),(!_vm.productSupplier.filters.productExists)?_c('a-icon',{staticClass:"cgreen",attrs:{"type":"check"}}):_vm._e()],1)],2)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Nome Fantasia ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `trading_name`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `trading_name`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome Fantasia"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`cnpj`) ? 'filled' : ''},[_vm._v("\n        CNPJ\n      ")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"},{name:"decorator",rawName:"v-decorator",value:([
          `cnpj`,
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `cnpj`,\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"CNPJ (opcional)"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showField),expression:"showField"}],attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:_vm.form.getFieldValue(`product_supplier_id`) ? 'filled' : ''},[_vm._v("\n        Sub-Fornecedor\n      ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `product_supplier_ids`,
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `product_supplier_ids`,\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"show-search":"","allow-clear":true,"mode":"multiple","optionFilterProp":"txt","placeholder":"Selecione um sub fornecedor"}},_vm._l((_vm.productSupplier.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n          "+_vm._s(item.id)+" -\n          "+_vm._s(item.trading_name.toUpperCase())+"\n        ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:_vm.form.getFieldValue(`category`) ? 'filled' : ''},[_vm._v("\n        Categoria\n      ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `category`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `category`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Categoria","mode":"multiple","optionFilterProp":"txt"}},_vm._l((_vm.categories),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name,"txt":item.name}},[_vm._v("\n          "+_vm._s(item.name)+"\n        ")])}),1)],1)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showField),expression:"showField"}],staticClass:"box"},[_c('AddressData',{attrs:{"requiredFields":{
        cep: false,
        location_neighborhood: false,
        location_state: false,
        location_street: false,
      },"fieldSizes":{
        cep: 10,
        location_street: 24,
        location_number: 8,
        location_complement: 16,
        location_neighborhood: 10,
        location_city: 10,
        location_state: 4,
        location_country: 10,
      },"form":_vm.form}})],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`company_id`) ? 'filled' : ''},[_vm._v("\n        Empresa\n      ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `company_id`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `company_id`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione uma empresa","optionFilterProp":"txt","show-search":""}},_vm._l((_vm.companiesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n          "+_vm._s(item.id)+" - "+_vm._s(item.trading_name)+"\n        ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`status`) ? 'filled' : ''},[_vm._v("\n        Status\n      ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `status`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `status`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Status","optionFilterProp":"txt"}},[_c('a-select-option',{attrs:{"value":"Ativo"}},[_vm._v(" Ativo ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Desativado"}},[_vm._v(" Desativado ")])],1)],1)],1),_vm._v(" "),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showField),expression:"showField"}],attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`website`) ? 'filled' : ''},[_vm._v("\n        Website\n      ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`website`]),expression:"[`website`]"}],staticClass:"travel-input",attrs:{"placeholder":"Website"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showField),expression:"showField"}],attrs:{"span":24}},[_c('a-form-item',{staticClass:"mb-30 travel-radio"},[_c('label',{staticClass:"filled"},[_vm._v(" Tipo de pagamento ")]),_vm._v(" "),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'invoice_type',
          {
            rules: [
              {
                required: false,
                message: 'Selecione um tipo de pagamento.',
              },
            ],
          },
        ]),expression:"[\n          'invoice_type',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Selecione um tipo de pagamento.',\n              },\n            ],\n          },\n        ]"}],attrs:{"options":_vm.invoices}})],1)],1),_vm._v(" "),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showField),expression:"showField"}],attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`invoice_closing_type`) ? 'filled' : 'filled'},[_vm._v("\n        Faturamento (Vencimento)\n      ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `invoice_closing_type`,
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório.',
              },
            ],
          },
        ]),expression:"[\n          `invoice_closing_type`,\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório.',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Faturamento (Vencimento)"}},_vm._l((_vm.invoiceTypes),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n          "+_vm._s(item.value)+"\n        ")])}),1)],1)],1),_vm._v(" "),(
      _vm.form.getFieldValue(`invoice_closing_type`) ==
        'Diferenciado (Dia útil/Vencimento)' && _vm.showField
    )?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`invoice_closing_day`) ? 'filled' : ''},[_vm._v("\n        Dia do Vencimento\n      ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `invoice_closing_day`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório.',
              },
            ],
          },
        ]),expression:"[\n          `invoice_closing_day`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório.',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input select",attrs:{"placeholder":"  Dia do Vencimento"}},_vm._l((_vm.days),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item}},[_vm._v("\n          "+_vm._s(item)+"\n        ")])}),1)],1)],1):_vm._e(),_vm._v(" "),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showField),expression:"showField"}],attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`invoice_payment_type`) ? 'filled' : 'filled'},[_vm._v("\n        Faturamento (Fechamento)\n      ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `invoice_payment_type`,
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório.',
              },
            ],
          },
        ]),expression:"[\n          `invoice_payment_type`,\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório.',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Fechamento"}},_vm._l((_vm.invoiceTypes),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n          "+_vm._s(item.value)+"\n        ")])}),1)],1)],1),_vm._v(" "),(
      _vm.form.getFieldValue(`invoice_payment_type`) ==
        'Diferenciado (Dia útil/Vencimento)' && _vm.showField
    )?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`invoice_payment_day`) ? 'filled' : ''},[_vm._v("\n        Dia do Fechamento\n      ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `invoice_payment_day`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório.',
              },
            ],
          },
        ]),expression:"[\n          `invoice_payment_day`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório.',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Dia do Fechamento"}},_vm._l((_vm.days),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item}},[_vm._v("\n          "+_vm._s(item)+"\n        ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(_vm.edit == true)?_c('a-col',{staticClass:"mb-20",attrs:{"span":24}},[_c('FileUpload',{attrs:{"folder":`public/product-supplier/${_vm.productSupplierId}`,"multiple":false,"deletable":true,"sortable":false,"meta":false,"theme":'default',"maxSize":'10MB',"accept":'image/*',"maxFiles":1,"text":'Logo do fornecedor',"userID":true,"userFolder":false,"url":_vm.form.getFieldValue(`logo`),"id":_vm.productSupplierId},on:{"fileURL":_vm.fileURL}}),_vm._v(" "),_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`logo`]),expression:"[`logo`]"}],staticStyle:{"text-transform":"none"},attrs:{"placeholder":"LOGO"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }